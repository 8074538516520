<template>
<div class="contents_855">
  <header-bar active="plan"></header-bar>
  <div v-show="isStep1">
    <h1 class="title2">プラン変更</h1>
    <div class="contents_box mg20">
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item2">プラン<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <select v-model="planPart1" class="w200" @change="onPlanChange()"
                  :class="{error_outline: this.errors.planPart1}">
                <option value="">選択してください</option>
                <option v-for="elem in planParts1" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </p>
            <p>
              <span class="iferror" v-show="errors.planPart1_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.planPart1_unchangeable">
                ▲ 一時停止中のため、単発プランに変更できません
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.notarea">
                ▲ 提供エリア外のためご利用いただけません
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">集配方法<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <select v-model="planPart2" class="w250" @change="onPlanChange()"
                  :class="{error_outline: this.errors.planPart2}">
                <option value="">選択してください</option>
                <option v-for="elem in lovs.pd_type" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </p>
            <p>
              <span class="iferror" v-show="errors.planPart2_required">
                ▲ 必須項目です
              </span>
            </p>
            <div v-if="showPdSameTime" class="wrap_input">
              <div class="slc">
                <input id="same_time_delivery_radio1" type="radio" value="" v-model="pdSameTime" @change="onPlanChange()">
                <label for="same_time_delivery_radio1" class="ml5">集配別</label>
              </div>
              <div class="slc">
                <input id="same_time_delivery_radio2" type="radio" value="1" v-model="pdSameTime" @change="onPlanChange()">
                <label for="same_time_delivery_radio2" class="ml5">集配同時（-400円/1個）</label>
              </div>
              <div class="notes" @click="showSameTimeDeliveryNoteModal = true">?</div>
            </div>
          </td>
        </tr>
        <tr>
          <th><p class="item2">バッグサイズ<span class="font_orange">*</span></p></th>
          <td>
            <p v-if="showBagSizeSlct">
              <select v-model="bagSize" class="w200" @change="onPlanChange()"
                  :class="{error_outline: this.errors.bagSize}">
                <option value="">選択してください</option>
                <option v-for="elem in sizeDisplay" v-bind:value="elem.key">
                  {{elem.display}}
                </option>
              </select>
            </p>
            <p v-else>
              {{SizeForHanding}}
            </p>
            <p>
              <span class="iferror" v-show="errors.bagSize_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">料金</p></th>
          <td>
            <p v-if="isOnetime">日時に応じて都度決済されます</p>
            <p v-else>{{price | currency('',0)}}円({{unitPrice | currency('',0)}}円/個)</p>
          </td>
        </tr>
        <tr v-if="!isOnetime">
          <th><p class="item3">お支払い方法<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <span v-for="elem in paymentMethods" :value="elem.key">
                <input type="radio" v-bind:id="`option${elem.key}`" :value="elem.key" v-model="payment_method"/>
                <label v-bind:for="`option${elem.key}`">{{elem.val}}払い</label>
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.payment_method_unselectable">
                ▲ 個人のお客様は請求書払いを選択できません
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.payment_method_onetime">
                ▲ 単発プランは請求書払いを選択できません
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.payment_method_plans">
                ▲ 選択されたお申し込み内容は請求書払いを選択できません
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.payment_method_unchangeable">
                ▲ 一時停止中のため、請求書払いに変更できません
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th class="form_line_l"></th>
            <td class="form_line_r">
              <edit-plan-notice-messages></edit-plan-notice-messages>
            </td>
        </tr>
      </table>

      <div class="err-msg-area" v-show="hasError">
        <span class="font_orange">
          入力エラーがあります
        </span>
      </div>

      <p class="submit_bx_l">
        <router-link :to="{name: 'plan'}" class="submit1 bg_gray">
          戻る
        </router-link>
      </p>
      <p class="submit_bx_r">
        <button @click="nextStep()" class="submit1 bg_green">
          確認画面
        </button>
      </p>
    </div>
  </div>

  <div v-show="isStep2">
    <h1 class="title2">プラン変更</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        下記の情報でよろしければ送信してください。
      </p>
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item2">プラン<span class="font_orange">*</span></p></th>
          <td><p>{{planPart1Disp}}</p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">集配方法<span class="font_orange">*</span></p></th>
          <td><p>{{planPart2Disp}}</p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">料金</p></th>
          <td>
            <p v-if="isOnetime">日時に応じて都度決済されます</p>
            <p v-else>{{price | currency('',0)}}円({{unitPrice | currency('',0)}}円/個)</p>
          </td>
        </tr>
        <tr v-if="!isOnetime">
          <th><p class="item2">お支払い方法</p></th>
          <td><p>{{payment_method_disp}}払い</p></td>
        </tr>
      </table>

      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
        <p><span class="font_orange" v-show="isStatusWithdrawn">
          このアカウントは退会済みです
        </span></p>
      </div>

      <p class="submit_bx_l">
        <button @click="prevStep()" class="submit1 bg_gray">
          戻る
        </button>
      </p>
      <p v-if="payment_method == 1 && !isOnetime" class="submit_bx_r">
        <button @click="updatePlan()" class="submit1 bg_green">
          カード情報入力へ
        </button>
      </p>
      <p v-else class="submit_bx_r">
        <button @click="updatePlan()" class="submit1 submit2 bg_green">
          OK
        </button>
      </p>

      <form id="payment-form" method="post"
          :action="paymentInfo.url" accept-charset="Shift_JIS">
        <input type="hidden" v-for="elem in paymentInfo.formParams"
          :name="elem.name" :value="elem.value">
        <input type="hidden" name="RETURL" :value="paymentInfo.retUrl">
      </form>
    </div>
  </div>

  <SameTimeDeliveryNoteModal
    v-if="showSameTimeDeliveryNoteModal"
    @dismiss="showSameTimeDeliveryNoteModal = false"
  />
</div>
</template>

<script>
import userApi from '@/api/user'
import areaInfoApi from '@/api/areaInfo'
import userMixin from '@/mixin/userMixin'
import { getValidPaymentMethodKeys } from '@/utils/userUtils'
import SameTimeDeliveryNoteModal from '@/components/lib/SameTimeDeliveryNoteModal.vue'
import { stoppedStatus, creditCardPayment, invoicePayment } from '@/consts/lov'
import { findPaymentMethod, findStatusType } from '@/utils/lovUtils'
import { isOnetimePlan, isMonthlyPlan } from '@/utils/planUtils'

export default {
  name: 'plan-edit',
  components: { SameTimeDeliveryNoteModal },
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      lovs: {},
      planMap: {},
      lovsMap: {},
      sizeDisplay: {},
      optionMagMap: {},

      planPart1: '',
      planPart2: '',
      bagSize: '',
      showBagSizeSlct: true,
      showPdSameTime: false,
      pdSameTime: '',
      pdSameTimeOrg: '',
      option: 0,
      planId: '',
      count: 1,
      price: '',
      unitPrice: '',
      payment_method: 1,
      payment_method_disp: '',
      company_name: '',
      planParts1: [],
      isOnetime: false,
      isOnetimeBefore: false,
      isMonthlyPlanBefore: false,
      paymentMethods: [],
      isStopped: false,
      isCreditCardPaymentStopped: false,

      areaInfos: [],
      zipcode: '',

      paymentInfo: {
        url: '',
        formParams: [],
        retUrl: '',
      },
      SizeForHanding: '',

      isStatusWithdrawn: false,
      errors: {},
      serverErrors: [],
      bagSizeOrg: '',

      showSameTimeDeliveryNoteModal: false,
    }
  },
  watch: {
    bagSize: function(newBagSize, oldBagSize) {
        const planParts1 = this.lovs.plan_part.filter(p => {
          // お試しプランは選択不可
          if (p['key'] === 't0') {
            return false
          // bagSizeがS,Mの場合は40、48、56個を選択できないようにする
          } else if ((newBagSize == 1 || newBagSize == 2) && (p['key'] === 't40' || p['key'] === 't48' || p['key'] === 't56')) {
            return false
          }
          return true
        })
        this.planParts1 =  planParts1

        // 40、48、56個を選択した状態で、bagSizeをS,Mに変更した場合は個数をリセットする
        if ((newBagSize == 1 || newBagSize == 2) && (this.planPart1 === 't40' || this.planPart1 === 't48' || this.planPart1 === 't56')) {
          this.planPart1 = ''
        }
    }
  },
  computed: {
    planPart1Disp() {
      const plan = this.planMap[this.planId]
      if (!plan) { return '' }
      return plan.name
    },
    planPart2Disp() {
      const pdType = this.lovs.pd_type
      if (!pdType) { return '' }
      let ret = ''
      pdType.forEach(e => {
        if (e.key === this.planPart2) {
          ret = e.val
        }
      })
      return ret
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  mixins: [userMixin],
  mounted() {
    window.master.$promise.then(mst => {
      this.lovs = mst.lovs
      this.planMap = mst.planMap
      this.lovsMap = mst.lovsMap
      this.optionMagMap = mst.lovsMap.user_plan_option_mag

      this.sizeDisplay = this.lovs.size.reduce((acc, e) => {
        if(e.key == 1){
          e.display = e.val + "（35L）"
        } else if(e.key == 2){
          e.display = e.val + "（80L）"
        } else if(e.key == 3){
          e.display = e.val + "（140L）"
        } else if(e.key == 4){
          e.display = e.val + "（250L）"
        }
        acc[e.key] = e;

        return acc
      }, {})

      this.$store.dispatch('user/getMe')
        .then(me => {
          this.payment_method = me.latest_payment_method_id
          this.company_name = me.company_name
          this.zipcode = me.send_addr_zipcode

          if (this.lovs.payment_method) {
            const validPaymentMethodKeys = getValidPaymentMethodKeys(me.is_company)
            this.paymentMethods = this.lovs.payment_method.filter(pm => {
              return validPaymentMethodKeys.includes(pm['key'])
            })
          }
          this.isStopped = findStatusType(this.lovs, me.status.toString())?.val === stoppedStatus
          const isCreditCardPayment = findPaymentMethod(this.lovs, me.latest_payment_method_id)?.val === creditCardPayment
          this.isCreditCardPaymentStopped = this.isStopped && isCreditCardPayment
        })

      userApi.getMyCurrentPlanWithOption().then(({ data }) => {
        //plan_idを_で区切って３つ目の値が1なら集配同時
        //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
        const planInfos = data.plan_id.split('_')
        if (planInfos.length > 2 && planInfos[2] == 1) {
          this.pdSameTime = '1'
        } else {
          this.pdSameTime = ''
        }
        this.pdSameTimeOrg = this.pdSameTime
        this.planPart1 = data.plan_id.split('_')[0]
        this.planPart2 = data.pickup_delivery_type
        this.bagSize = this.planMap[data.plan_id].size
        this.bagSizeOrg = this.bagSize
        this.option = data.take_over_option
        this.isOnetimeBefore = isOnetimePlan(data.plan_id)
        this.isMonthlyPlanBefore = isMonthlyPlan(data.plan_id)
        this.onPlanChange()
      })

      if (this.lovs.plan_part) {
        const planParts1 = this.lovs.plan_part.filter(p => {
          // お試しプランは選択不可
          return p['key'] !== 't0'
        })
        this.planParts1 =  planParts1
      }
    })
    areaInfoApi.index().then(({ data }) => {
      this.areaInfos = data
    })
  },
  methods: {
    nextStep() {
      const pdSameTimeStr = '同時集配設定を変更する場合は曜日固定設定が解除されます。'
      const bagSizeStr = 'サイズを変更する場合は当月末に残個数が破棄されます。'
      const changePlanTypeStr = '単発プランに変更する場合は当月末に残個数が破棄されます。'
      const nextStr = 'よろしければOKを押して確認画面へお進み下さい。'
      const newLineStr = '\r\n'
      let confirmStr = ''

      //ダイアログ表示
      if (!this.isOnetimeBefore && this.isOnetime) {
        confirmStr = changePlanTypeStr + newLineStr + nextStr
      } else if ((this.pdSameTime !== this.pdSameTimeOrg) && (this.bagSize !== this.bagSizeOrg)) {
        confirmStr = bagSizeStr + newLineStr + pdSameTimeStr + newLineStr + nextStr
      } else if (this.pdSameTime !== this.pdSameTimeOrg) {
        confirmStr = pdSameTimeStr + newLineStr + nextStr
      } else if (this.bagSize !== this.bagSizeOrg) {
        confirmStr = bagSizeStr + newLineStr + nextStr
      }

      if (!this.isOnetimeBefore && confirmStr.length > 0 && !confirm(confirmStr)) {
        return
      }

      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
      this.payment_method_disp = this.lovsMap.payment_method[this.payment_method].val
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    checkStep1() {
      this.errors = {}
      let result = true

      if (!this.planPart1) {
        this.errors['planPart1'] = true
        this.errors['planPart1_required'] = true
        result = false
      }
      if (!this.planPart2) {
        this.errors['planPart2'] = true
        this.errors['planPart2_required'] = true
        result = false
      }
      if (!this.bagSize) {
        this.errors['bagSize'] = true
        this.errors['bagSize_required'] = true
        result = false
      }
      if (!this.checkArea({plan_id: this.planId, zipcode: this.zipcode}, this.areaInfos)) {
        this.errors['notarea'] = true
        result = false
      }
      if (this.isStopped && this.isMonthlyPlanBefore && isOnetimePlan(this.planId)) {
        this.errors['planPart1_unchangeable'] = true
        result = false
      }

      const invoicePaymentSelected = findPaymentMethod(this.lovs, this.payment_method)?.val === invoicePayment
      if (!this.company_name && invoicePaymentSelected) {
        this.errors['payment_method_unselectable'] = true
        result = false
      }
      if (this.planPart1 === 'tx' && invoicePaymentSelected) {
        this.errors['payment_method_onetime'] = true
        result = false
      }
      if ((this.planPart1 === 't2' || this.planPart1 === 't4') && this.bagSize === '1' && invoicePaymentSelected) {
        this.errors['payment_method_plans'] = true
        result = false
      }
      if (this.planPart1 === 't2' && this.bagSize === '2' && invoicePaymentSelected) {
        this.errors['payment_method_plans'] = true
        result = false
      }
      const creditCardPaymentNotSelected = findPaymentMethod(this.lovs, this.payment_method)?.val !== creditCardPayment
      if (this.isCreditCardPaymentStopped && creditCardPaymentNotSelected) {
        this.errors['payment_method_unchangeable'] = true
        result = false
      }

      return result
    },
    checkStep2() {
      return true
    },
    onPlanChange() {
      if (this.planPart1 == 'tx') {
        this.bagSize = this.lovsMap.size[1].key
        this.SizeForHanding = '単発（45L）'
        this.showBagSizeSlct = false
        this.isOnetime = true
      } else {
        this.isOnetime = false
        this.showBagSizeSlct = true
      }
      if (!this.planPart1 || !this.planPart2 || !this.bagSize || !this.count) {
        if (this.planPart1 && this.planPart2) {
          this.showPdSameTime = true
        }
        this.price = ''
        this.unitPrice = ''
        return
      }
      // 直接受け渡す以外は不在受け渡しとする
      const planIdSuffix = this.planPart2 === '1' ? '1' : '2'

      //Sサイズ以外はplanIdにサイズを追記する
      let bagSizeSuffix = ''
      const sizeMap = this.lovsMap.size
      if (this.bagSize != 1) {
        bagSizeSuffix = '_' + sizeMap[this.bagSize].val
      }

      // 単発プランの場合、初回費用固定
      if (this.planPart1 === 'tx') {
        this.planId = this.planPart1 + '_' + planIdSuffix
        this.price = ''
        this.unitPrice = ''
        this.showPdSameTime = false
        this.pdSameTime = ''
        return
      }
      // お試しプランは選択不可のため、お試しプラン契約の場合は初期値を選択
      if (this.planPart1 === 't0') {
        this.planPart1 = ''
        this.price = ''
        this.unitPrice = ''
        this.showPdSameTime = false
        this.pdSameTime = ''
        return
      }
      // 月２個プランは、集配同時プランはない
      if (this.planPart1 === 't2') {
        this.planId = this.planPart1 + '_' + planIdSuffix + bagSizeSuffix
        this.price = this.planMap[this.planId].price * this.count
        this.setOptionPrice()
        this.unitPrice = this.price / this.planMap[this.planId].wash_count
        this.showPdSameTime = false
        this.pdSameTime = ''
        return
      }
      // 配達と次回の集配の集配を同時にする場合、末尾に付与
      this.showPdSameTime = true
      const planIdSuffix2 = this.pdSameTime === '1' ? '_1' : ''
      this.planId = this.planPart1 + '_' + planIdSuffix + planIdSuffix2 + bagSizeSuffix
      if (!this.planMap[this.planId]) {
        this.price = ''
        this.unitPrice = ''
        return
      }
      this.price = this.planMap[this.planId].price * this.count
      this.setOptionPrice()
      this.unitPrice = this.price / this.planMap[this.planId].wash_count
    },
    setOptionPrice(){
      if (this.option) this.price = this.price * this.optionMagMap[this.option].val
    },
    updatePlan() {
      this.isStatusWithdrawn = false
      this.serverErrors = []
      const data = {
        plan_id: this.planId,
        pickup_delivery_type: this.planPart2,
        payment_method: this.payment_method,
        user_plan_option: this.option,
      }
      userApi.updateMyPlan(data)
        .then(({ data }) => {
          //クレジット決済(単発以外)
          if (this.payment_method == 1 && this.planPart1 !== 'tx') {
            this.updatePaymentFormValues(data.payment)
            setTimeout(() => {
              const form = document.querySelector('#payment-form')
              form.submit()
            }, 0)
            //請求書、単発
          } else {
            if (data.payment) {
              this.updatePaymentFormValues(data.payment)
              setTimeout(() => {
                const form = document.querySelector('#payment-form')
                form.submit()
              }, 0)
            } else {
              this.$router.push({
                name: 'plan-edit-complete'
              })
            }
          }
        })
        .catch(e => {
          if (e.response.data.reason === 'status_withdrawn') {
            this.isStatusWithdrawn = true
            this.$store.dispatch('localStorage/set', {
              key: 'serverToken',
              val: null,
            })
            return
          }
          const errRes = e.response.data.errors || [];
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
    updatePaymentFormValues(obj) {
      this.paymentInfo.url = obj.url
      this.paymentInfo.formParams = obj.form_params
      this.paymentInfo.retUrl = `${location.protocol}//${location.host}/${obj.ret_url_path}`
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}

.wrap_input {
  width: 100%;
  margin: 0 auto 0 auto;
  text-align: left;
  overflow: hidden;
}

.wrap_input .slc {
  float: left;
  margin: 10px 15px 0 0;
}

.wrap_input .notes {
  float: left;
  width: 26px;
  height: 26px;
  margin: 10px 0 0 0;
  background-color: #B7E6F1;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  border-radius: 13px;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .wrap_input .slc {
    float: none;
  }
  .wrap_input .notes {
    float: none;
  }
}

</style>
