export function isOnetimePlan(planId) {
  const planIdPart1 = planId.split('_')[0]
  return planIdPart1 === 'tx'
}

export function isTrialPlan(planId) {
  const planIdPart1 = planId.split('_')[0]
  return planIdPart1 === 't0'
}

export function isMonthlyPlan(planId) {
  const planIdPart1 = planId.split('_')[0]
  return planIdPart1 !== 't0' && /^t\d+/.exec(planIdPart1) !== null
}
